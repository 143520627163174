import * as React from 'react'
import styled from 'styled-components'
import config from 'config'
import useAccount from 'hooks/account/useAccount'
import { useIntentLayer } from 'modules/ui'
import useNewsletter from 'hooks/useNewsletter'
import { registerNewsletterNL } from 'modules/ui/actions'
import NewsletterIntentContent from './NewsletterIntentContent'

export default function NewsletterIntentLayer() {
  const account = useAccount()
  const newsletter = useNewsletter()
  const isB2b = account.channel === 'b2b'
  const isFrance = config.locale === 'fr-fr'
  const intentLayer = useIntentLayer()

  React.useEffect(() => {
    if (account.loggedIn) {
      newsletter.fetchOptInStatus()
    }
  }, [])

  React.useEffect(() => {
    if (newsletter.optInStatus) {
      registerNewsletterNL()
    }
  }, [newsletter.optInStatus])

  // do not show the intent layer
  // if the intent layer is not visible or
  // the ab test is not active
  // or the featureflag is not active
  // or the user is already subscribed

  if (
    !intentLayer.visible ||
    (!config.abTests.wdv3313NewsletterIntentLayer &&
      !config.features.showNewsletterIntentLayer) ||
    newsletter.optInStatus
  )
    return null
  return (
    <Wrapper
      $isB2B={isB2b}
      $isFrance={isFrance}
      data-cy-ctx="app/NewsletterIntentLayer"
    >
      <NewsletterIntentContent isB2B={isB2b} className="newsletter-content" />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  $isB2B: boolean
  $isFrance: boolean
}>`
  > .newsletter-content {
    ${(p) =>
      document.body.classList.contains('ab-mode-a') && p.$isFrance
        ? `display: none;`
        : `display: block;`}
  }
`
